.contactPage {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: fit-content;
  margin: 0 auto;
  height: calc(100vh - 72px);
  
  h1 {
    font-size: 24px;
    font-weight: 700;
    font-family: 'Montserrat';
    margin-bottom: 8px;
  }

  p {
    font-family: 'Poppins';
    font-size: 14px;
    font-weight: 400;
    line-height: 130%;
    margin-bottom: 16px;
  }

  form {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 416px;

    label {
      display: flex;
      font-family: 'Poppins';
      font-size: 16px;
      font-weight: 400;
      line-height: 150%;
      flex-direction: column;
      margin-bottom: 8px;
      width: 100%;
      text-align: left;
    }

    .emailInput,
    .messageInput {
      padding: 10px;
      border: 1px solid #e0e0e0;
      border-radius: 8px;
      font-size: 16px;
      font-family: 'Poppins';
      font-weight: 400;
      width: 100%;
      resize: none;
      margin-bottom: 20px;
      &::placeholder {
        color: #ababab;
      }
    }
    .messageInput {
      height: 134px;
      line-height: 1.5;
      padding: 10px;
      resize: none;
    }
    button {
      width: 100%;
      margin-top: 20px;
      padding: 10px 20px;
      background-color: #258bff;
      color: #fff;
      font-family: 'Poppins';
      font-size: 16px;
      font-weight: 400;
      line-height: 24px;
      letter-spacing: 0em;
      border: none;
      border-radius: 5px;
      cursor: pointer;
      transition: background-color 0.3s;

      &:hover {
        background-color: #1c7fd8;
      }
    }
  }
}
