.footerContainer {
  position: fixed;
  bottom: 0;
  height: 69px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: white;
  border-top: 1px solid #e0e0e0;
  z-index: 10;
}

.footerText {
  color: #5c5c5c;
  text-align: center;
}

.footerLink {
  text-decoration: underline;
  color: #5c5c5c;
}
