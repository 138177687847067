.closeModalButton {
  position: absolute;
  font-size: 30px;
  top: 20px;
  right: 20px;
  z-index: 1;
  cursor: pointer;
}

.paymentFormInfo {
  z-index: 1;
  padding: 0 50px 40px;
  font-size: 12px;
  text-align: center;
  line-height: 16px;
  color: #ababab;
}

.paymentFormLink {
  color: #5c5c5c;
}
