.modalContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  z-index: 1;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background: rgba(2, 9, 18, 0.7);
}

.modalContent {
  position: relative;
  background-color: #ffffff;
  border-radius: 8px;
  max-width: 524px;
}
