.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(2, 9, 18, 0.7);
  z-index: 9999;
}

.modal {
  display: flex;
  flex-direction: column;
  width: 620px;
  background-color: white;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.header {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 72px;
  margin-bottom: 20px;
  position: relative;
  border-radius: 8px 8px 0px 0px;
  background-color: #3cd770;
  width: 100%;
}

.status {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
}

.success {
  background-color: #01d401;
}

.error {
  background-color: #f96056;
}

.icon {
  width: 106px;
  height: 106px;
  bottom: -55px;
  position: absolute;
  left: auto;
}

.content {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 40px;
}

.title {
  font-size: 24px;
  line-height: 1.5;
  font-weight: bold;
  text-align: center;
  margin-bottom: 10px;
}

.description {
  font-size: 16px;
  line-height: 24px;
  text-align: center;
}

.buttonContainer {
  display: flex;
  justify-content: center;
}

.button {
  font-size: 16px;
  cursor: pointer;
  color: black;
  transition: background-color 0.3s ease;
}
.failButton {
  font-size: 14px;
  cursor: pointer;
  padding: 10px 32px;
  color: #f96056;
  border: 1px solid #f96056;
  border-radius: 8px;
  margin-top: 10px;
  background-color: transparent;
}

.button:active {
  transform: translateY(1px);
}
