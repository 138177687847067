.overlay {
  position: fixed;
  top: 73px;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(2, 9, 18, 0.7);
  z-index: 9;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  color: #fff;

  .container {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;

    h1 {
      font-family: 'Montserrat';
      font-style: normal;
      font-weight: 700;
      font-size: 48px;
      margin-bottom: 70px;
    }

    button {
      background-color: #258bff;
      height: 35px;
      color: white;
      font-weight: 400;
      border-radius: 5px;
      border: none;
      width: 250px;
      height: 48px;
      font-family: 'Poppins';
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      cursor: pointer;
      &:hover {
        background-color: #1e70e5;
      }
      &:active {
        background-color: #0e55b5;
      }
    }
  }

  .disclaimerGroup {
    max-width: 1240px;
    margin: 0 auto;
    padding: 0 24px;
    box-sizing: border-box;
    line-height: 1.2;
    font-size: 14px !important;
    text-align: center;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    align-self: end;

    li:not(:last-child) {
      margin-bottom: 8px;
    }
  }
}

.previewContainer {
  margin-top: 72px;

  canvas {
    margin: 0 auto;
  }
}
