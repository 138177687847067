.container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 20px 360px;

  h1 {
    font-size: 32px;
    font-weight: bold;
    margin-top: 100px;
    margin-bottom: 16px;
  }

  p {
    margin-bottom: 1.4rem;
    text-align: justify;
    strong span {
      font-weight: bold;
    }
    span {
      line-height: 20px;
    }
  }

  ol {
    margin-left: 1.4em;
    li {
      font-weight: normal;
      margin-bottom: 20px;
    }
  }
}

.listContainer {
  margin-bottom: 1rem;
  strong span {
    margin-left: 1rem;
    font-weight: bold;
  }
  span {
    margin-left: 2rem;
    font-weight: bold;
  }
}

.shadow {
  box-shadow: 0px 4px 20px rgba(37, 139, 255, 0.3);
}
