.header {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 72px;
  padding: 0 50px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: white;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);

  .button_contact {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    color: rgb(0, 0, 0);
    font-weight: 400;
    font-size: 16px;
    background-color: transparent;
    a {
      text-decoration: none;
      color: inherit;
    }
    &.active {
      border-bottom: 2px solid #258bff;
    }
  }
}

.shadow {
  box-shadow: 0px 4px 20px rgba(37, 139, 255, 0.3);
}

.btn_Done {
  background-color: #258bff;
  padding: 10px 32px;
  color: white;
  font-family: 'Poppins';
  font-weight: 400;
  font-size: 16px;
  line-height: 150%;
  border-radius: 8px;
  border: none;
  cursor: pointer;
  &:hover {
    background-color: #1e70e5;
  }
  &:active {
    background-color: #0e55b5;
  }
}
.disabled {
  background-color: #cde4ff;
  &:hover {
    background-color: #cde4ff;
  }
}
